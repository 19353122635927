//@ts-nocheck
import React, { Component } from "react";
import {
  TouchableOpacity,
  View,
  Text,
  ViewStyle,
  TextStyle,
} from "react-native";
import styles from "./styles";
import LinearGradientComponent from "../LinearGradient";
import COLORS from "../../utils/colors";

export interface ChipsProps {
  /** state or theme of the chips */
  state?: "default" | "not active" | "active";
  /** text on the chips */
  text?: string;
  /** style of the chips container */
  style?: ViewStyle;
  /** style of the text on the chips */
  textStyle?: TextStyle;
  /** color 1 for the gradient in active chips */
  gradientColor1?: string;
  /** color 2 for the gradient in active chips */
  gradientColor2?: string;
  /** icon url in the chips */
  icon?: React.ReactNode;
  /** position of the icon it should be either left or right */
  iconPosition?: "left" | "right";
  /** on press function of the chips */
  onPress?: () => void;
}

class Chips extends Component<ChipsProps> {
  render() {
    const {
      state = "default",
      text,
      style,
      textStyle,
      gradientColor1 = COLORS.COLOR_MEDIUMBLUE,
      gradientColor2 = COLORS.COLOR_LIGHTBLUE,
      icon,
      iconStyle,
      iconPosition = "left",
      onPress,
      ...props
    } = this.props;

    const defaultChips = () => {
      return (
        <TouchableOpacity {...props}>
          <View
            style={[
              styles.defaultChips,
              { flexDirection: iconPosition == "left" ? "row" : "row-reverse" },
              style,
            ]}
          >
            {icon}
            {text && (
              <Text
                style={[
                  styles.text,
                  { color: COLORS.COLOR_MEDIUMBLUE },
                  textStyle,
                ]}
              >
                {text}
              </Text>
            )}
          </View>
        </TouchableOpacity>
      );
    };

    const notActiveChips = () => {
      return (
        <TouchableOpacity {...props}>
          <View
            style={[
              styles.notActiveChips,
              { flexDirection: iconPosition == "left" ? "row" : "row-reverse" },
              style,
            ]}
          >
            {icon}
            {text && (
              <Text
                style={[
                  styles.text,
                  { color: COLORS.COLOR_DISABLE_GREY },
                  textStyle,
                ]}
              >
                {text}
              </Text>
            )}
          </View>
        </TouchableOpacity>
      );
    };

    const activeChips = () => {
      return (
        <TouchableOpacity {...props}>
          <LinearGradientComponent
            style={{
              borderRadius: 26,
              ...style,
            }}
            colors={[gradientColor2, gradientColor1]}
          >
            <View
              style={[
                styles.activeChips,
                {
                  flexDirection: iconPosition == "left" ? "row" : "row-reverse",
                },
                // {
                //   backgroundColor: gradientColor1
                //     ? gradientColor1
                //     : COLORS.COLOR_MEDIUMBLUE,
                // },
                style,
              ]}
            >
              {icon}
              {text && (
                <Text
                  style={[
                    styles.text,
                    { color: COLORS.COLOR_WHITE },
                    textStyle,
                  ]}
                >
                  {text}
                </Text>
              )}
            </View>
          </LinearGradientComponent>
        </TouchableOpacity>
      );
    };

    return state == "active"
      ? activeChips()
      : state == "not active"
      ? notActiveChips()
      : defaultChips();
  }
}

export default Chips;
