import { StyleSheet } from "react-native";
import COLORS from "../../utils/colors";
const hs = (value: number) => value;
const ws = (value: number) => value;
const ms = (value: number) => value;

export const styles = StyleSheet.create({
  parentContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: ws(900),
  },
  parentContainerVertical: {
    alignItems: "center",
  },
  mainContainer: {
    // height: hs(100),
    width: ws(300),
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    flexDirection: "row",
    height: hs(60),
    width: ws(300),
    alignItems: "center",
    justifyContent: "center",
  },
  stepContainer: {
    height: hs(32),
    width: ws(32),
    borderRadius: 100,
    backgroundColor: "#94A3B8",
    alignItems: "center",
    justifyContent: "center",
    marginRight: ws(8),
    overflow: "hidden",
  },
  linearStepContainer: {
    height: 32,
    width: 32,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
  },
  linearwebStepContainer: {
    marginRight: 8,
    paddingLeft: 7
  },
  stepActive: {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: hs(3),
    width: "100%",
  },
  step: {
    fontFamily: 'Inter-Bold',
    fontSize: ms(16),
    fontWeight: "700",
    color: COLORS.COLOR_WHITE,
  },
  activeTag: {
    fontFamily: 'Inter-Bold',
    fontSize: ms(16),
    fontWeight: "700",
    color: COLORS.COLOR_COOLGRAY,
  },
  tag: {
    fontFamily: 'Inter-Bold',
    fontSize: ms(16),
    fontWeight: "700",
    color: COLORS.COLOR_GRAY,
  },
  imageStyle: {
    height: hs(16),
    width: ws(16),
    resizeMode: "stretch",
    overflow: "hidden",
  },
  separator: {
    width: 25,
    height: 3,
    borderRadius: 20,
    backgroundColor: COLORS.COLOR_GRAY_PLACEHOLDER,
  },
});

