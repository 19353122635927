//@ts-nocheck
import React from "react";
import { TouchableOpacity, View, Text } from "react-native";
import style from "./style";
import { FormHeaderProps } from "./interface";

class FormHeader extends React.Component<FormHeaderProps> {
  render() {
    const {
      titleText,
      iconRight,
      detailsText,
      iconPress,
      titleTextStyle,
      detailsTextStyle,
      containerStyle,
    } = this.props;

    const titleTextStyles = [style.titleTextStyle, titleTextStyle];
    const detailsTextStyles = [style.detailsTextStyle, detailsTextStyle];

    return (
      <View style={[style.mainContainer, containerStyle]}>
        <View style={style.subView}>
          <Text style={titleTextStyles}>{titleText}</Text>
          <TouchableOpacity onPress={iconPress}>{iconRight}</TouchableOpacity>
        </View>
        {detailsText ? (
          <View style={style.detailViewStyle}>
            <Text style={detailsTextStyles}>{detailsText}</Text>
          </View>
        ) : (
          <></>
        )}
      </View>
    );
  }
}

export default React.memo(FormHeader);
