
import { StyleSheet } from "react-native";
import {
  horizontalScale,
} from "../../../utils/screenRatio";

export default StyleSheet.create({
  contanier: {
    width:  "100%",
    height: 60,
    flexDirection: "row",
    backgroundColor: "white",
    borderBottomColor: "#E2E8F0",
    borderBottomWidth: 1,
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerLogoTextStyle: {
    color: "#334155",
    fontSize: 20,

    marginHorizontal: horizontalScale(10),
  },
  userSymbol: {
    color: "black",
    width:18,
    height:18,
    fontWeight:'bold'
  },
  symbol: {
    marginHorizontal: horizontalScale(4),
    color: "black",
    width:18,
    height:18,
    fontWeight:'bold'

  },
  loginText:{
    fontSize: 12,
    marginRight: horizontalScale(3)
  },
  cartStyle: {
    width: 15,
    height: 15,
    borderRadius: 18 / 2,
    backgroundColor: "#8833FF",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    right: 10,
    bottom:10
  },
  flexStyle: {
    flexDirection: "row",
    alignItems: "center",
  },
  navButtonNameStyle: {
    color:'#334155',
    fontSize: 12,
    marginHorizontal: horizontalScale(6),
  },
  leftIconStyle: {
    color: "#94A3B8",
    marginLeft:10
  },
  avtarStyle: {
    borderColor: "#F0E5FF",
    width: 32,
    height: 32,
    borderRadius: 40 / 2,
    marginHorizontal:horizontalScale(6)
  },
  cartText: { 
    color: "white", 
    fontSize: 8 
  },
  inputStyle:{
    borderRadius: 100, 
    width: 360 
  },
  headerLogoStyle:{
    width:149,
    height:12,
    marginLeft:40
  }
});