import { StyleSheet } from "react-native";
const hs = (value: number) => value
const ws = (value: number) => value
const ms = (value: number) => value


export const styles = StyleSheet.create({
    container: {
        width: ws(327),
    },
    placeHolderContainer: {
        flexDirection: 'row',
        width: ws(327),
        height: 50,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderWidth: ms(0),
        borderColor: '#1B1B3A',
        borderTopLeftRadius: ms(8),
        borderTopRightRadius: ms(8),
        paddingHorizontal: ws(16)
    },
    contentContainer: {
        position: 'absolute',
        top: 50,
        left: 0,
        width: '100%',
        borderBottomLeftRadius: ms(8),
        borderBottomRightRadius: ms(8),
        backgroundColor: 'white',
        marginTop: hs(0),
        zIndex: 100
    },
    listContainer: {
        flexDirection: 'row',
        width: '100%',
        height: hs(56),
        borderRadius: ms(0),
        backgroundColor: 'transparent',
        marginTop: hs(2),
        paddingHorizontal: ws(16),
        alignItems: 'center'

    },
    selectedListContainer: {
        flexDirection: 'row',
        width: '100%',
        height: hs(56),
        borderRadius: ms(0),
        marginTop: hs(2),
        paddingHorizontal: ws(16),
        alignItems: 'center',
        backgroundColor: '#E1CCFF'
    },
    placeholderTextStyle: {
        // fontFamily: 'Inter-Regular',
        fontSize: ms(18),
        fontWeight: '300',
        color: 'black',
    },
    imageContainer: {
        height: hs(24),
        width: ws(24),
        overflow: 'hidden',
        alignSelf: 'center'
    },
    iconContainer: {
        height: hs(18),
        width: ws(18),
        overflow: 'hidden',
        marginRight: hs(8)
    },
    image: {
        height: "100%",
        width: "100%",
        resizeMode: 'stretch'
    }
})
