import { Dimensions } from "react-native";
const {width} = Dimensions.get("window");


function getPlatformType() {

  if (width >= 320 && width<= 568) {
    return "phone";
  } else if (width >= 601 && width <= 1280) {
    return "tab";
  } else if (width >= 1280) {
    return "web";
  }
}
export const FULL_HEIGHT=Dimensions.get('window').height;

export default getPlatformType;