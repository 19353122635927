//@ts-nocheck
import React, { Component } from "react";
import CheckboxSingle from "./component/checkboxsingle";
import CheckboxMultiple from "./component/checkboxmultiple";
import {
  CheckBoxMultipleProps,
  CheckBoxProps,
  CheckBoxSingleProps,
} from "./interface";

class CheckBox extends Component<
  CheckBoxProps & (CheckBoxSingleProps | CheckBoxMultipleProps)
> {
  render() {
    return (
      <>
        {this?.props?.checkBoxGroup === true ? (
          <CheckboxMultiple {...this?.props} />
        ) : (
          <CheckboxSingle {...this?.props} />
        )}
      </>
    );
  }
}

export default CheckBox;
