import { StyleSheet } from "react-native";
import COLORS from "../../utils/colors";
import { moderateScale } from "../../utils/screenRatio";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    width: 70,
  },
  menuProviderStyles: {
    // padding: 100,
    overflow: "visible",
  },
  optionText: {
    fontSize: moderateScale(7),
    color: COLORS.COLOR_COOLGRAY,
    alignItems: "center",
    fontWeight: "400",
    // fontFamily: "Inter-Regular",

    marginLeft: 12,
  },
  icon: {
    tintColor: COLORS.COLOR_LISTTEXT,
    height: 15,
    width: 12,
  },
  highlightedIconView: {
    borderRadius: 25,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
  },
  highlightedIcon: {
    height: 15,
    width: 12,
  },
  optionContainer: {
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-between",
    margin: 3,
    borderRadius: 8,
  },
  menuOptionStyle: {
    borderRadius: 8,
  },
  rightIcon: {
    tintColor: COLORS.COLOR_LISTTEXT,
    height: 12,
    width: 16,
  },
  leftIcon: {
    tintColor: COLORS.COLOR_LISTTEXT,
    height: 12,
    width: 16,
  },
  submenuContainer: {
    position: "absolute",
    left: "100%",
    top: 0,
    backgroundColor: COLORS.COLOR_WHITE,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: COLORS.COLOR_LIGHTGRAY,
    shadowOffset: { width: 0, height: 2 },
    shadowColor: COLORS.COLOR_BLACK,
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 4,
    minWidth: 200,
    marginLeft: 12,
  },
  imageView: {
    flexDirection: "row",
    alignItems: "center",
  },
  containerRight: {
    position: "absolute",
    top: 20,
    left: 40,
  },
  containerLeft: {
    position: "absolute",
    top: 20,
    right: 70,
  },
});

export const optionsStyles = StyleSheet.create({
  optionsContainer: {
    minWidth: 200,
    backgroundColor: COLORS.COLOR_WHITE,
    paddingVertical: 7,
    // marginTop:80,
    paddingHorizontal: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: COLORS.COLOR_LIGHTGRAY,
    // elevation: 0, // Remove the shadow effect
    // shadowOpacity: 0, // Remove the shadow effect
    shadowOffset: { width: 0, height: 2 },
    shadowColor: COLORS.COLOR_BLACK,
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 4,
    // marginLeft: -180,
  },
});

export const nestedOptionsStyles = {
  optionsContainer: {
    backgroundColor: COLORS.COLOR_WHITE,
    paddingVertical: 7,
    paddingHorizontal: 12,
    marginHorizontal: 11,
    borderRadius: 5,
    //  marginLeft: -180,
  },
  optionWrapper: {
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
};

export default styles;
