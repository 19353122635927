import { StyleSheet } from 'react-native'

const hs = (value: number) => value
const ws = (value: number) => value
const ms = (value: number) => value

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: hs(54),
    width: ws(245),
    backgroundColor: 'white',
    borderRadius: ms(6),
    zIndex: 5,
  },
  leftContainer: {
    height: hs(42),
    width: ws(42),
    borderRadius: ms(6),
    marginHorizontal: ws(6),
    backgroundColor: '#F0E5FF',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  centerContainer: {
    height: '100%',
    flex: 1,
    justifyContent: 'space-evenly',
    marginHorizontal: ws(4),
    paddingVertical: hs(4),
  },
  rightContainer: {
    height: hs(24),
    width: ws(24),
    marginHorizontal: 20,
    alignSelf: 'center',
  },
  title: {
    // fontFamily: 'Inter-Bold',
    fontSize: ms(12),
    fontWeight: '400',
    color: '#000',
  },
  subTitle: {
    // fontFamily: 'Inter-Bold',
    fontSize: ms(12),
    fontWeight: '400',
    color: '#475569',
  },
  imageStyle: {
    height: '100%',
    width: '100%',
    resizeMode: 'stretch',
    overflow: 'hidden',
  },
  rightIconStyle: {
    height: '100%',
    width: '100%',
    resizeMode: 'stretch',
    overflow: 'hidden',
  },
  iconStyle: {
    height: hs(18),
    width: ws(18),
    overflow: 'hidden',
    resizeMode: 'stretch',
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 2,
    width: '0%',
    backgroundColor: '#8833FF',
    zIndex: 1,
  },
  shodow: {
    shadowOffset: { width: 1, height: 2 },
    shadowColor: '#171717',
    shadowOpacity: 0.2,
    shadowRadius: 10,
  },
})
