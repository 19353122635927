import React from "react";
import { View } from "react-native";
import Button from "../Button/Button";
import styles from "./styles";

export interface bottomBarProps {
  container?: object;
  leftButtonTitle: string;
  leftButtonStyle?: object;
  leftButtonTextStyle?: object;
  rightButtonTitle: string;
  rightbtnStyle?: object;
  rightBtnTextStyle?: object;
  gradientColor1?: string;
  gradientColor2?: string;
}

class FormBottomBar extends React.Component<bottomBarProps> {
  render() {
    const {
      container,
      leftButtonTitle,
      leftButtonStyle,
      leftButtonTextStyle,
      rightButtonTitle,
      rightbtnStyle,
      rightBtnTextStyle,
      gradientColor1,
      gradientColor2,
    } = this.props;
    return (
      //@ts-ignore
      <View style={[styles.container, container]}>
        <Button
          style={{ ...styles.leftButtonStyle, ...leftButtonStyle }}
          textStyle={[styles.rightBtnTextStyle, leftButtonTextStyle]}
          theme="secondary"
          text={leftButtonTitle}
        />
        <Button
          style={{ ...styles.rightbtnStyle, ...rightbtnStyle }}
          textStyle={[styles.rightBtnTextStyle, rightBtnTextStyle]}
          theme="primary"
          gradientColors={
            gradientColor1 && gradientColor2
              ? [gradientColor1, gradientColor2]
              : undefined
          }
          text={rightButtonTitle}
        />
      </View>
    );
  }
}

export default FormBottomBar;
