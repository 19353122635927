import { StyleSheet } from "react-native";
import COLORS from "../../utils/colors";
import { horizontalScale, moderateScale } from "../../utils/screenRatio";

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: "center",
    marginBottom: 10,
  },
  hoverView: {
    padding: 8,
    borderRadius: 7,
  },
  breadcrumbText: {
    color: COLORS.COLOR_NEUTRAL_GRAY,
    fontSize: moderateScale(10),
    fontWeight: "400",
  },
  boldText: {
    color: COLORS.COLOR_LIGHTBLACK,
    fontSize: moderateScale(10),
    fontWeight: "700",
  },
  icon: {
    width: 10,
    height: 10,
    tintColor: COLORS.COLOR_DISABLE_GREY,
    marginLeft: horizontalScale(2),
    marginRight: horizontalScale(2),
  }
});
export default styles;