//@ts-nocheck
import React from "react";
import style from "../style";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { RadioButtonProps, RadioButtonSingleProps } from "../interface";
import LinearGradientComponent from "../../../LinearGradient";

interface State {
  radioButtonSelected?: any;
}

class RadioButtonSingle extends React.Component<
  RadioButtonSingleProps & RadioButtonProps,
  State
> {
  constructor(props: any) {
    super(props);

    this.state = {
      radioButtonSelected: props.isRadioButtonSelected,
    };
  }

  onRadioButtonClick = () => {
    const { radioButtonSelected } = this.state;
    const { onRadioButtonPress } = this.props;
    this.setState({ radioButtonSelected: !radioButtonSelected }, () => {
      onRadioButtonPress?.();
    });
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.isRadioButtonSelected !== this.props.isRadioButtonSelected) {
      this.setState({ radioButtonSelected: this.props.isRadioButtonSelected });
    }
  }

  render() {
    const {
      isRadioButtonDisable,
      radioButtonDisableSelected,
      radioButtonDisableSelectedButtonStyle,
      radioButtonDisableButtonStyle,
      radioButtonText,
      viewGradientColorStyle,
      radioButtonStyle,
      radioButtonTextStyle,
      disableRadioButtonTextStyle,
    } = this.props;
    const { radioButtonSelected } = this.state;

    return (
      <>
        {isRadioButtonDisable ? (
          <View style={style.radioButtonViewStyle}>
            <View
              style={
                radioButtonDisableSelected
                  ? [
                      style.radioButtonDisableSelectedButtonStyle,
                      radioButtonDisableSelectedButtonStyle,
                    ]
                  : [
                      style.radioButtonDisableButtonStyle,
                      radioButtonDisableButtonStyle,
                    ]
              }
            ></View>
            {radioButtonText && radioButtonText.length > 0 && (
              <Text
                style={[
                  style.disableRadioButtonTextStyle,
                  disableRadioButtonTextStyle,
                ]}
              >
                {radioButtonText}
              </Text>
            )}
          </View>
        ) : (
          <View style={style.radioButtonViewStyle}>
            <TouchableOpacity
              testID="radio-button-single"
              style={
                radioButtonSelected
                  ? [style.radioButtonSelectedButtonStyle, radioButtonStyle]
                  : [style.radioButtonStyle, radioButtonStyle]
              }
              onPress={this.onRadioButtonClick}
            >
              {radioButtonSelected && (
                <>
                  {viewGradientColorStyle &&
                  viewGradientColorStyle?.length > 0 ? (
                    <LinearGradientComponent colors={viewGradientColorStyle}>
                      <View style={style.linearradioButtonSelectedButtonStyle}>
                        <View style={style.radioButtonInnerView} />
                      </View>
                    </LinearGradientComponent>
                  ) : (
                    <View style={style.radioButtonInnerView} />
                  )}
                </>
              )}
            </TouchableOpacity>

            {radioButtonText && radioButtonText?.length > 0 && (
              <Text style={[style.radioButtonTextStyle, radioButtonTextStyle]}>
                {radioButtonText}
              </Text>
            )}
          </View>
        )}
      </>
    );
  }
}

export default React.memo(RadioButtonSingle);
