//@ts-nocheck
import React, { Component, createRef } from "react";
import { Image, View, TouchableOpacity, Text, FlatList } from "react-native";
import styles from "./styles";

interface Item {
  id: number;
  title: string;
  image: string;
}

export interface ItemProps {
  products: any;
  mainView?: object;
  imageView?: object;
  titleTextStyle?: object;
  descTextStyle?: object;
  priceBlockStyle?: object;
  priceTextStyle?: object;
  rightIconPosition?: object;
  leftIconPosition?: object;
  btnStyle?: object;
  gradientColor1?: string;
  gradientColor2?: string;
  btnTextStyle?: object;
  containerStyle?: object;
}

export interface ItemState {
  visibleItem?: any;
}

class WebCarousel extends Component<ItemProps, ItemState> {
  listRef = createRef<FlatList<Item>>();
  constructor(props) {
    super(props);
    this.listRef = createRef();
    this.state = {
      visibleItem: [],
    };
  }

  onViewCallBack = (viewableItems) => {
    this.setState({ visibleItem: viewableItems });
  };

  scrollToIndex = (index, animated = true) => {
    this.listRef.current &&
      this.listRef.current.scrollToIndex({
        index: index,
        animated: animated,
      });
  };

  renderText = ({}) => {
    return <Text>Hii</Text>;
  };

  renderItem = ({ item }) => {
    const {
      mainView,
      imageView,
      titleTextStyle,
      descTextStyle,
      priceBlockStyle,
      priceTextStyle,
    } = this.props;
    return (
      <View style={[styles.mainView, mainView]}>
        <View style={[styles.blockView, imageView]}>
          <Text style={[styles.titleText, titleTextStyle]}>
            {item?.titleText}
          </Text>
          <Text style={[styles.DescText, descTextStyle]}>{item?.DescText}</Text>
        </View>
        <View>
          <Text style={[styles.titleText, titleTextStyle]}>
            {item?.titleText2}
          </Text>
          <Text style={[styles.DescText, descTextStyle]}>
            {item?.DescText2}
          </Text>
          <View style={[styles.priceBlock, priceBlockStyle]}>
            <Text style={[styles.priceText, priceTextStyle]}>
              {item?.price}
            </Text>
            {item?.children}
          </View>
        </View>
      </View>
    );
  };

  render() {
    const { visibleItem } = this.state;
    const { products, containerStyle } = this.props;

    return (
      <View style={[{ flexDirection: "row" }, containerStyle]}>
        {visibleItem?.viewableItems?.length &&
        visibleItem?.viewableItems[0].index === 0 ? null : (
          <TouchableOpacity
            testID={"leftIconButton"}
            style={[styles.leftIconPosition, this?.props?.leftIconPosition]}
            onPress={() => {
              const index = products.findIndex((element) => {
                return element.id == visibleItem?.viewableItems[0].item.id;
              });
              this.scrollToIndex(index - 1);
            }}
          >
            <View style={styles.circle}>
              <Image
                source={{ uri: "https://i.imgur.com/wk0j48w.png" }}
                style={styles.iconStyle}
              ></Image>
            </View>
          </TouchableOpacity>
        )}

        <View style={{ width: "90%" }}>
          <FlatList
            data={products}
            ref={this.listRef}
            horizontal={true}
            renderItem={this.renderItem}
            onEndReachedThreshold={0.5}
            scrollEnabled={false}
            showsHorizontalScrollIndicator={false}
            onViewableItemsChanged={this.onViewCallBack}
            keyExtractor={(item) => `${item.id}`}
          />
        </View>

        <TouchableOpacity
          testID={"rightIconButton"}
          style={[styles.rightIconPosition, this.props.rightIconPosition]}
          onPress={() => {
            const index = products.findIndex((element) => {
              return (
                element.id ==
                visibleItem?.viewableItems[
                  visibleItem?.viewableItems.length - 1
                ].item.id
              );
            });
            this.scrollToIndex(index - 1);
            // if (visibleItem?.viewableItems.length == 1) {
            // this.scrollToIndex(index)
            // } else {
            //   this.scrollToIndex(index - 1)
            // }
          }}
        >
          <View style={styles.circle}>
            <Image
              source={{ uri: "https://i.imgur.com/wWX59PK.png" }}
              style={styles.iconStyle}
            ></Image>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default WebCarousel;
