//@ts-nocheck
import React, { Component } from "react";
import { Image, View, TouchableOpacity, Text } from "react-native";
import styles from "./styles";
import Button from "../Button/Button";
import STRINGS from "../../utils/strings";
import STRINGS_TESTCASE from "../../utils/stringsTestCase";

export interface PopoverConfirmComptProps {
  /** style for the popover container */
  containerStyle?: {};
  /** title text of the popover */
  titleText?: string;
  /** style for the title text */
  titleStyle?: {};
  /** description text of the popover */
  descriptionText?: string;
  /** style for the description text */
  descriptionStyle?: {};
  /** style for the cancel button */
  btnCancelStyle?: {};
  /** text style for the cancel button */
  txtCancelStyle?: {};
  /** style for the done button */
  btnDoneStyle?: {};
  /** text style for the done button */
  txtDoneStyle?: {};
  /** url for the close icon */
  closeIcon?: React.ReactNode;
  /** color 1 for the gradient on the button */
  gradientColor1: string;
  /** color 2 for the gradient on the button */
  gradientColor2: string;
}

interface PopoverState {
  isModalVisible: boolean;
}

class PopoverConfirmCompt extends Component<
  PopoverConfirmComptProps,
  PopoverState
> {
  constructor(props: PopoverConfirmComptProps) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  onPressCloseIcon = () => {};

  onPressCancel = () => {};

  onPressDone = () => {};

  render() {
    const {
      containerStyle,
      titleText = STRINGS.DEFAULT_TXT_POPOVR_1,
      titleStyle,
      descriptionText = STRINGS.DEFAULT_TXT_POPOVR_2,
      descriptionStyle,
      btnCancelStyle,
      txtCancelStyle,
      btnDoneStyle,
      txtDoneStyle,
      closeIcon,
      gradientColor1,
      gradientColor2,
    } = this.props;

    return (
      <View style={[styles.innerContainer, containerStyle]}>
        <TouchableOpacity
          onPress={() => this.onPressCloseIcon()}
          testID={STRINGS_TESTCASE.POPOVER_INPUT_COMPONENT_ICON}
          style={styles.iconContainer}
        >
          {closeIcon ? (
            closeIcon
          ) : (
            <Image
              source={{ uri: "https://i.imgur.com/amHs6X5.png" }}
              style={[styles.crossIcon]}
            />
          )}
        </TouchableOpacity>
        <Text style={[styles.txtTitle, titleStyle]}>{titleText}</Text>
        <Text style={[styles.txtDescription, descriptionStyle]}>
          {descriptionText}
        </Text>
        <View style={styles.horizontalLine} />
        <View style={styles.viewBtnContainer}>
          <Button
            style={{ ...styles.btnCancelDone, ...btnCancelStyle }}
            textStyle={[styles.txtCancel, txtCancelStyle]}
            onPress={() => this.onPressCancel()}
            theme={"secondary"}
          >
            {STRINGS.CANCEL}
          </Button>
          <Button
            style={btnDoneStyle}
            textStyle={{ ...styles.txtDelAccount, ...txtDoneStyle }}
            onPress={() => this.onPressDone()}
            gradientColors={[gradientColor1, gradientColor2]}
            theme="primary"
          >
            {STRINGS.DONE}
          </Button>
        </View>
      </View>
    );
  }
}

export default PopoverConfirmCompt;
