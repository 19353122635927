import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'

const styles = StyleSheet.create({
  mainView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  subView: {
    backgroundColor: COLORS.COLOR_WHITE,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // boxShadow: '0px 6px 15px -3px rgba(0, 0, 0, 0.15)',
    shadowColor: '#171717',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  msgStyle: {
    fontSize: 16,
    fontWeight: '400',
    color: COLORS.COLOR_COOLGRAY,
    // fontFamily: 'Inter-Regular',
  },
  leftIconStyle: {
    height: 18,
    width: 18,
    marginRight: 8,
  },
  textView: { flex: 1, flexWrap: 'wrap' },
  subTextView: {
    flexDirection: 'column',
    flex: 1,
    // textAlign: 'center',
  },
  text: {
    fontSize: 16,
    fontWeight: '700',
    color: COLORS.COLOR_LISTTEXT,
    // fontFamily: 'Inter-Regular',
  },
  subText: {
    fontSize: 14,
    fontWeight: '400',
    color: COLORS.COLOR_SUB_TEXT,
    // fontFamily: 'Inter-Regular',
  },
  btnStyle: {
    backgroundColor: COLORS.COLOR_LIGHTPURPLE,
    marginLeft: 10,
    paddingVertical: 6,
    paddingHorizontal: 10,
    borderRadius: 6,
    alignItems: 'center',
  },
  btnTextStyle: {
    // fontFamily: 'Inter-Medium',
    fontSize: 14,
    color: COLORS.COLOR_HIGHLIGHTBLUE,
    fontWeight: '700',
  },
  rightIconStyle: {
    height: 18,
    width: 18,
    marginLeft: 60,
    color: COLORS.COLOR_GRAY,
    borderRadius: 8,
  },
  avatarStyle: {
    marginRight: 12,
  },
})

export default styles
