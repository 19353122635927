//@ts-nocheck
import React, { Component } from "react";
import { Animated, View } from "react-native";
import {
  ProgressIndicatorBarProps,
  ProgressIndicatorProps,
} from "../interface";
import style from "../style";

interface ProgressIndicatorBarState {
  animatedWidth?: any;
}
class ProgressIndicatorBar extends Component<
  ProgressIndicatorBarProps & ProgressIndicatorProps,
  ProgressIndicatorBarState
> {
  animatedWidth: Animated.Value;
  constructor(props) {
    super(props);
    this.animatedWidth = new Animated.Value(0);
  }

  componentDidMount() {
    const { indicatorToValue, indicatorDuration } = this.props;
    Animated.timing(this.animatedWidth, {
      toValue: indicatorToValue,
      duration: indicatorDuration,
      useNativeDriver: false,
    }).start();
  }

  componentDidUpdate(prevProps) {
    const { indicatorToValue, indicatorDuration } = this.props;
    if (
      indicatorToValue !== prevProps.indicatorToValue ||
      indicatorDuration !== prevProps.indicatorDuration
    ) {
      Animated.timing(this.animatedWidth, {
        toValue: indicatorToValue,
        duration: indicatorDuration,
        useNativeDriver: false,
      }).start();
    }
  }

  render() {
    const {
      headerIndicator,
      loaderContainer,
      loaderSmallContainer,
      loaderProgressStyle,
    } = this.props;

    const interpolatedWidth = this.animatedWidth.interpolate({
      inputRange: [0, 100],
      outputRange: ["0%", "100%"],
      extrapolate: "clamp",
    });

    const loaderContainerStyle = headerIndicator
      ? [style.loaderContainer, loaderContainer]
      : [style.loaderSmallContainer, loaderSmallContainer];

    return (
      <View>
        <View style={loaderContainerStyle}>
          <Animated.View
            style={[
              style.loaderProgress,
              loaderProgressStyle,
              { width: interpolatedWidth },
            ]}
          />
        </View>
      </View>
    );
  }
}

export default ProgressIndicatorBar;
