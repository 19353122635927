//@ts-nocheck
import React, { Component } from "react";
import { TouchableOpacity, View, Text } from "react-native";
import { Image } from "../../../Image/Image";
import style from "../style";
import { CheckBoxProps, CheckBoxSingleProps } from "../interface";
import LinearGradientComponent from "../../../LinearGradient";

interface CheckBoxSingleState {
  checkBoxSelected?: any;
}

class CheckBoxSingle extends Component<
  CheckBoxSingleProps & CheckBoxProps,
  CheckBoxSingleState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      checkBoxSelected: this.props.isCheckBoxSelected,
    };
  }

  componentDidUpdate(prevProps: CheckBoxSingleProps & CheckBoxProps) {
    if (prevProps.isCheckBoxSelected !== this.props.isCheckBoxSelected) {
      this.setState({ checkBoxSelected: this.props.isCheckBoxSelected });
    }
  }

  onCheckBoxClick = () => {
    this.setState((prevState) => ({
      checkBoxSelected: !prevState.checkBoxSelected,
    }));
    // this.props.onCheckBoxPress;
  };

  render() {
    const {
      checkBoxButtonStyle,
      checkBoxDisableButtonStyle,
      checkBoxDisableSelected,
      checkBoxDisableSelectedButtonStyle,
      checkBoxText,
      checkBoxTextStyle,
      disableCheckBoxTextStyle,
      ischeckBoxDisable,
      rightClickIcon,
      viewGradientColorStyle,
      //@ts-ignore
      checkBoxViewStyle,
    } = this?.props;
    const { checkBoxSelected } = this?.state;
    return (
      <>
        {ischeckBoxDisable ? (
          <View style={[style.checkBoxViewStyle, checkBoxViewStyle]}>
            <View
              style={
                checkBoxDisableSelected
                  ? [
                      style.checkBoxDisableSelectedButtonStyle,
                      checkBoxDisableSelectedButtonStyle,
                    ]
                  : [
                      style.checkBoxDisableButtonStyle,
                      checkBoxDisableButtonStyle,
                    ]
              }
            >
              {checkBoxDisableSelected && rightClickIcon}
            </View>
            {checkBoxText && checkBoxText?.length > 0 && (
              <Text
                style={[
                  style.disableCheckBoxTextStyle,
                  disableCheckBoxTextStyle,
                ]}
              >
                {checkBoxText}
              </Text>
            )}
          </View>
        ) : (
          <View style={[style.checkBoxViewStyle, checkBoxViewStyle]}>
            <TouchableOpacity
              style={
                checkBoxSelected
                  ? [style.checkBoxSelectedButtonStyle, checkBoxButtonStyle]
                  : [style.checkBoxButtonStyle, checkBoxButtonStyle]
              }
              onPress={this.onCheckBoxClick}
            >
              {viewGradientColorStyle && viewGradientColorStyle?.length > 0 ? (
                <>
                  {checkBoxSelected ? (
                    <LinearGradientComponent
                      colors={viewGradientColorStyle}
                      style={{ borderRadius: 5 }}
                    >
                      <View style={style.linearcheckBoxSelectedButtonStyle}>
                        {rightClickIcon}
                      </View>
                    </LinearGradientComponent>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>{checkBoxSelected && rightClickIcon}</>
              )}
            </TouchableOpacity>
            {checkBoxText && checkBoxText?.length > 0 && (
              <Text style={[style.checkBoxTextStyle, checkBoxTextStyle]}>
                {checkBoxText}
              </Text>
            )}
          </View>
        )}
      </>
    );
  }
}

export default CheckBoxSingle;
