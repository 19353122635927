import { StyleSheet, Platform } from "react-native";
import COLORS from "../../utils/colors";

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: COLORS.COLOR_WHITE,
    borderRadius: 10,
    width: "100%",
  },

  input: {
    fontSize: 16,
    fontWeight: "400",
    color: COLORS.COLOR_COOLGRAY,
    width: "90%",
    ...Platform.select({
      web: { outlineStyle: "none" },
    }),
  },
  error: {
    fontSize: 10,
    color: COLORS.COLOR_RED,
    fontFamily: "Inter-Regular",
    marginTop: 2,
  },
  symbol: {
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: "700",
    fontSize: 16,
    color: COLORS.COLOR_GRAY,
    fontFamily: "Inter-Bold",
  },
  leftIcon: {
    marginLeft: 8,
    color: COLORS.COLOR_GRAY,
    height: 18,
    width: 18,
    textAlign: "center",
  },
  rightIcon: {
    marginRight: 10,
    color: COLORS.COLOR_GRAY,
    height: 18,
    width: 18,
    justifyContent: "flex-end",
  },
  label: {
    fontSize: 16,
    fontWeight: "700",
    color: "#334155",
  },
  labelContainer: {
    marginBottom: 8,
    marginHorizontal: 4,
  },
  chipStyle: {
    flexDirection: "row",
  },
  chipItemStyle: {
    paddingHorizontal: 3,
  },
  icon: {
    width: 20,
    height: 20,
  },
});
export default styles;
