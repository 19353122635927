//@ts-nocheck
import React, { Component } from "react";
import { Image, TouchableOpacity, View, Text } from "react-native";
import styles from "./styles";
import Button from "../Button/Button";
import STRINGS from "../../utils/strings";
import STRINGS_TESTCASE from "../../utils/stringsTestCase";
import Input from "../Input";

export interface PopoverInputComptProps {
  /** style for the popover container */
  containerStyle?: {};
  /** title text of the popover */
  titleText?: string;
  /** style for the title text */
  titleStyle?: {};
  /** description text of the popover */
  descriptionText?: string;
  /** style for the description text */
  descriptionStyle?: {};
  /** style for the cancel button */
  btnCancelStyle?: {};
  /** text style for the cancel button */
  txtCancelStyle?: {};
  /** style for the done button */
  btnDoneStyle?: {};
  /** text style for the done button */
  txtDoneStyle?: {};
  /** url for the close icon */
  closeIcon?: React.ReactNode;
  /** array of text inputs */
  textInputs?: Array<object>;
  /** color 1 for the gradient on the button */
  gradientColor1: string;
  /** color 2 for the gradient on the button */
  gradientColor2: string;
}

interface PopoverState {
  isModalVisible: boolean;
}

class PopoverInputCompt extends Component<
  PopoverInputComptProps,
  PopoverState
> {
  constructor(props: PopoverInputComptProps) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  // const [name, setName] = useState<string>('');
  // const [emailAddress, setEmailAddress] = useState<string>('');
  // const [modalVisible, setModalVisible] = useState<boolean>(true);

  onPressCloseIcon = () => {};

  onPressCancel = () => {};

  onPressDone = () => {};

  render() {
    const {
      containerStyle,
      titleText = STRINGS.POPOVER_TITLE,
      titleStyle,
      descriptionText = STRINGS.POPOVER_INPUT_TEXT,
      descriptionStyle,
      btnCancelStyle,
      txtCancelStyle,
      btnDoneStyle,
      txtDoneStyle,
      textInputs,
      closeIcon,
      gradientColor1,
      gradientColor2,
    } = this.props;

    return (
      <View style={[styles.innerContainer, containerStyle]}>
        <TouchableOpacity
          onPress={() => this.onPressCloseIcon()}
          testID={STRINGS_TESTCASE.POPOVER_INPUT_COMPONENT_ICON}
          style={styles.iconContainer}
        >
          {closeIcon ? (
            closeIcon
          ) : (
            <Image
              source={{ uri: "https://i.imgur.com/amHs6X5.png" }}
              style={[styles.crossIcon]}
            />
          )}
        </TouchableOpacity>
        <Text style={[styles.txtTitleInput, titleStyle]}>{titleText}</Text>
        <View style={styles.horizontalLine} />
        <Text style={[styles.txtDescriptionInput, descriptionStyle]}>
          {descriptionText}
        </Text>

        <>
          {textInputs?.map((item: any, index: number) => (
            <View key={index} style={styles.inputContainer}>
              <Input
                placeholder={item?.placeHolder}
                label={item?.title}
                maxLength={100}
                textStyle={{ fontSize: 16 }}
                size={"big"}
              />
            </View>
          ))}
        </>

        <View style={styles.horizontalLine} />
        <View style={styles.viewBtnContainer}>
          <Button
            style={{ ...styles.btnCancelDone, ...btnCancelStyle }}
            textStyle={[styles.txtCancel, txtCancelStyle]}
            onPress={() => this.onPressCancel()}
            theme={"secondary"}
          >
            {STRINGS.CANCEL}
          </Button>
          <Button
            theme="primary"
            style={btnDoneStyle}
            textStyle={[styles.txtDelAccount, txtDoneStyle]}
            onPress={() => this.onPressDone()}
            gradientColors={[gradientColor1, gradientColor2]}
          >
            {STRINGS.DONE}
          </Button>
        </View>
      </View>
    );
  }
}

export default PopoverInputCompt;
