import { StyleSheet } from "react-native";
import COLORS from "../../../utils/colors";

export default StyleSheet.create({
  toggleButtonStyle: {
    width: 20,
    height: 20,
    borderRadius: 20 / 2,
    borderWidth: 1,
    borderColor: COLORS.COLOR_COOLGRAY,
  },
  toggleSelectedButtonStyle: {
    width: 20,
    height: 20,
    borderRadius: 20 / 2,
    borderWidth: 1,
    borderColor: COLORS.COLOR_COOLGRAY,
    backgroundColor: COLORS.COLOR_MEDIUMBLUE,
    justifyContent: "center",
  },
  toggleTextStyle: {
    fontSize: 16,
    color: COLORS.COLOR_LISTTEXT,
    marginLeft: 10,
  },
  toggleViewStyle: {
    flexDirection: "row",
  },
  symbol: {
    width: 10,
    height: 10,
    alignSelf: "center",
  },
  toggleDisableButtonStyle: {
    width: 20,
    height: 20,
    borderRadius: 20 / 2,
    borderWidth: 1,
    borderColor: COLORS.COLOR_GREY_INPUT,
    justifyContent: "center",
  },
  toggleDisableSelectedButtonStyle: {
    width: 20,
    height: 20,
    borderRadius: 20 / 2,
    borderWidth: 1,
    borderColor: COLORS.COLOR_GREY_INPUT,
    backgroundColor: COLORS.COLOR_GREY_INPUT,
    justifyContent: "center",
  },
  disabletoggleTextStyle: {
    fontSize: 16,
    color: COLORS.COLOR_GRAY_PLACEHOLDER,
    marginLeft: 10,
  },
  toggleInnerView: {
    width: 8,
    height: 8,
    borderRadius: 8 / 2,
    backgroundColor: "white",
    alignSelf: "center",
  },
  switchContainer: {
    width: 48,
    height: 28,
    borderRadius: 40,
    justifyContent: 'center',
    paddingHorizontal: 2,
  },
  thumb: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: 'white',
    position: 'absolute',
  },
});
