//@ts-nocheck
import React, { Component } from "react";
import { View, FlatList, TouchableOpacity, Text, Image } from "react-native";
import styles from "./styles";
import CONSTANTS from "../../utils/constants";
import COLORS from "../../utils/colors";
import STRINGS from "../../utils/strings";

interface BreadcrumbItem {
  id: string;
  name: string;
}

interface BreadcrumbsProps {
  /** Data for Breadcrumbs */
  pageData: BreadcrumbItem[];
  /** Container  style */
  containerStyle?: object;
  icon?: React.ReactNode;
  textStyle?: object;
  activeTextStyle?: object;
  hoverViewStyle?: object;
  size?: "small" | "big";
  flatListView?: object;
  activePageIndex?: number;
  onIndexChange?: (index: number) => void;
}

interface BreadcrumbsState {
  selectedItem: number | null;
  hoveredIndex: number | null;
}

const getSize = (size: "small" | "big"): number => {
  switch (size) {
    case CONSTANTS.SIZE_SMALL:
      return 14;
    case CONSTANTS.SIZE_BIG:
      return 20;
    default:
      return 14;
  }
};

class Breadcrumbs extends Component<BreadcrumbsProps, BreadcrumbsState> {
  constructor(props: BreadcrumbsProps) {
    super(props);
    this.state = {
      selectedItem: props.activePageIndex || null,
      hoveredIndex: null,
    };
  }

  onIndexChangeVal = (index: number): void => {
    this.setState({ selectedItem: index });
    const { onIndexChange } = this.props;
    onIndexChange && onIndexChange(index);
  };

  renderItem = ({
    item,
    index,
  }: {
    item: BreadcrumbItem;
    index: number;
  }): React.ReactElement => {
    const { selectedItem, hoveredIndex } = this.state;
    const { icon, activeTextStyle, textStyle, size, hoverViewStyle } =
      this.props;
    const isSelected = selectedItem === index;
    const isHovered = hoveredIndex === index;

    return (
      <View style={styles.container}>
        <React.Fragment key={index}>
          {index > 0 &&
            (icon ? (
              icon
            ) : (
              <Image
                resizeMode="contain"
                style={[styles.icon]}
                source={{
                  uri: "https://i.imgur.com/JuKMaKC.png",
                }}
              />
            ))}
          <TouchableOpacity
            testID={STRINGS.BTN_TESTID}
            onPress={() => this.onIndexChangeVal(index)}
            // onHoverIn={() => this.setState({ hoveredIndex: index })}
            // onHoverOut={() => this.setState({ hoveredIndex: null })}
          >
            <View
              style={[
                styles.hoverView,
                {
                  backgroundColor: isHovered
                    ? COLORS.COLOR_BTNGRAY
                    : "transparent",
                },
                hoverViewStyle,
              ]}
            >
              <Text
                style={[
                  isSelected ? styles.boldText : styles.breadcrumbText,
                  { fontSize: getSize(size!) },
                  isSelected ? activeTextStyle : textStyle,
                ]}
              >
                {item.name}
              </Text>
            </View>
          </TouchableOpacity>
        </React.Fragment>
      </View>
    );
  };

  render() {
    const { pageData, containerStyle, flatListView } = this.props;

    return (
      <View style={[flatListView]}>
        <FlatList
          horizontal
          data={pageData}
          contentContainerStyle={[containerStyle]}
          keyExtractor={(item: BreadcrumbItem) => item.id}
          renderItem={({ item, index }) => this.renderItem({ item, index })}
        />
      </View>
    );
  }
}

export default Breadcrumbs;
