import React, { createContext } from "react";

type ContextType = {
  newClick: boolean;
  providerChecker: ({ children }) => React.ReactNode;
};

export const BuilderContext = createContext({
  newClick: false,
  providerChecker: undefined,
} as ContextType);
