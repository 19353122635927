import React, { useState } from "react";
import { TouchableWithoutFeedback, View } from "react-native";

import { BuilderContext } from "./Builder.context";

const BuilderProvider = ({ children }) => {
  const [newClick, setNewClick] = useState(false);

  const onPress = (e) => {
    setNewClick(!newClick);
  };

  return (
    <BuilderContext.Provider
      value={{
        newClick,
        providerChecker: ({ children }) => <>{children}</>,
      }}
    >
      <TouchableWithoutFeedback
        onPress={onPress}
        style={{
          backgroundColor: "transparent",
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <View style={{ flex: 1 }}>{children}</View>
      </TouchableWithoutFeedback>
    </BuilderContext.Provider>
  );
}

export default BuilderProvider
