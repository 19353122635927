//@ts-nocheck

import React, { Component } from "react";
import { TextInput, View, Text, ViewStyle, TextStyle } from "react-native";
import styles from "./styles";
import COLORS from "../../utils/colors";
import CONSTANTS from "../../utils/constants";

export interface InputProps {
  placeholder?: string;
  onChangeText?: (text: string) => void;
  size?: "small" | "medium" | "big";
  error?: string;
  suffix?: string;
  prefix?: string;
  label?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  labelTextStyle?: TextStyle;
  prefixStyle?: TextStyle;
  suffixStyle?: TextStyle;
  errorStyle?: TextStyle;
  textStyle?: TextStyle;
  style?: ViewStyle;
  maxLength?: number;
  highlightColor?: string;
  icon?: string;
  iconStyle?: ViewStyle;
}

interface InputState {
  value: string | null;
}

class Input extends Component<InputProps, InputState> {
  constructor(props: InputProps) {
    super(props);
    this.state = {
      value: null,
    };
  }

  onChangeValue = (value: string) => {
    if (value !== null || value !== undefined || value !== "") {
      this.setState({ value: value });
      const { onChangeText } = this.props;
      if (onChangeText) {
        onChangeText(value);
      }
    }
  };

  render() {
    const {
      placeholder,
      rightIcon,
      size,
      error,
      suffix,
      prefix,
      label,
      leftIcon,
      leftIconStyle,
      labelTextStyle,
      highlightColor,
      prefixStyle,
      suffixStyle,
      errorStyle,
      textStyle,
      style,
      maxLength,
      rightIconStyle,
      icon,
      iconStyle,
      ...props
    } = this.props;
    const { value } = this.state;

    return (
      <>
        {label && (
          <View style={styles.labelContainer}>
            <Text style={[styles.label, labelTextStyle]}>{label}</Text>
          </View>
        )}

        <View
          style={[
            styles.container,
            { borderColor: this.getBorderColor(error, value, highlightColor) },
            style,
          ]}
        >
          {leftIcon}
          <Text style={[styles.symbol, prefixStyle]}>{prefix}</Text>
          <TextInput
            style={[
              {
                ...styles.input,
                height: this.getSize(size),
                textAlign: suffix ? "right" : "left",
                marginLeft: 0,
                zIndex: 1,
              },
              textStyle,
            ]}
            value={value || ""}
            placeholder={placeholder}
            placeholderTextColor={COLORS.COLOR_GRAY_PLACEHOLDER}
            onChangeText={this.onChangeValue}
            maxLength={maxLength}
            {...props}
          />
          {suffix && <Text style={[styles.symbol, suffixStyle]}>{suffix}</Text>}

          {rightIcon}
        </View>
        {!!error && (
          <View>
            <Text style={[styles.error, errorStyle]}>{error}</Text>
          </View>
        )}
      </>
    );
  }

  getSize = (size: "small" | "medium" | "big") => {
    switch (size) {
      case CONSTANTS.SIZE_SMALL:
        return 32;
      case CONSTANTS.SIZE_BIG:
        return 56;
      case CONSTANTS.SIZE_MEDIUM:
      default:
        return 44;
    }
  };

  getBorderColor = (error?: string, value?: string, color?: string) => {
    if (error) {
      return COLORS.COLOR_ERROR;
    } else if (value) {
      return color ? color : COLORS.COLOR_HIGHLIGHTBLUE;
    } else {
      return COLORS.COLOR_GREY_INPUT;
    }
  };
}

export default Input;
