import COLORS from "./colors";

export const themeData = {
  primary: {
    gradientColors: [COLORS.COLOR_LIGHTBLUE, COLORS.COLOR_MEDIUMBLUE],
    activityIndicatorColor: COLORS.COLOR_LIGHTPINK,
    color: COLORS.COLOR_WHITE,
  },
  secondary: {
    activityIndicatorColor: COLORS.COLOR_DARKBLUE,
    color: COLORS.COLOR_MEDIUMBLUE,
  },
  dark: {
    activityIndicatorColor: COLORS.COLOR_LIGHTPINK,
    color: COLORS.COLOR_MEDIUMBLUE,
  },
};
