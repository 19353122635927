//@ts-nocheck
import React, { Component } from "react";
import {
  FlatList,
  ScrollView,
  TouchableOpacity,
  View,
  Text,
} from "react-native";
import style from "./style";
import Avatar from "../../Avatar";
import LinearGradientComponent from "../../LinearGradient";
import { SideMenuProps } from "./interface";

// export interface SideMenuProps {
//   mainTitle?: string;
//   subMenuData?: Array<Object>;
//   sideButtonTabData?: Array<Object>;
//   source?: string;
//   subMenu?: boolean;
//   tabMenu?: boolean;
//   smallTabBarContainer?: StyleProp<ViewStyle>;
//   sideBarContainer?: StyleProp<ViewStyle>;
//   sideBartitleStyle?: StyleProp<TextStyle>;
//   sideTabIconPressStyle?: Object;
//   sideTabIconStyle?: Object;
//   sideTabButtonTouchTextStyle?: StyleProp<TextStyle>;
//   sideTabButtonTextStyle?: StyleProp<TextStyle>;
//   sideMenuButtonTouchStyle?: Object;
//   sideMenuButtonStyle?: Object;
//   sideMenuTouchIcon?: Object;
//   sideMenuIcon?: Object;
//   sideMenuTouchNameStyle?: StyleProp<TextStyle>;
//   sideMenuNameStyle?: StyleProp<TextStyle>;
//   sideMenuDescriptionTouchStyle?: StyleProp<TextStyle>;
//   sideMenuDescriptionStyle?: StyleProp<TextStyle>;
//   imageIconName?: string;
//   barGradientColor?: Array<string>;
//   selectedTabGradientColor?: Array<string>;
//   tabMenuActiveIndex?: number;
//   subMenuActiveIndex?: number;
//   imageStyle?: ImageStyle;
//   sideBartitleView?: object;
//   children?: any;
// }

export interface SideMenuState {
  iconData: boolean;
  id: number;
  tabIconData: boolean;
  tabIconId: number;
}

class SideMenu extends Component<SideMenuProps, SideMenuState> {
  constructor(props: any) {
    super(props);
    this.state = {
      iconData: false,
      id: this?.props?.subMenuActiveIndex || 0,
      tabIconData: false,
      tabIconId: this?.props?.tabMenuActiveIndex || 0,
    };
  }

  componentDidUpdate(prevProps) {
    const { tabMenuActiveIndex } = this.props;
    if (
      tabMenuActiveIndex &&
      tabMenuActiveIndex !== prevProps.tabMenuActiveIndex
    ) {
      this.setState({
        tabIconId: this?.props?.tabMenuActiveIndex,
        tabIconData: true,
      });
    }
    const { subMenuActiveIndex } = this.props;
    if (
      subMenuActiveIndex &&
      subMenuActiveIndex !== prevProps.subMenuActiveIndex
    ) {
      this.setState({
        id: this?.props?.tabMenuActiveIndex,
        iconData: true,
      });
    }
  }

  renderSideMenuData = ({ item }: any) => {
    const {
      sideMenuButtonTouchStyle,
      sideMenuButtonStyle,
      sideMenuTouchNameStyle,
      sideMenuNameStyle,
      sideMenuDescriptionTouchStyle,
      sideMenuDescriptionStyle,
    } = this?.props;
    const { id, iconData, tabIconId } = this.state;
    return (
      <TouchableOpacity
        key={item.id}
        style={
          item.id == id && iconData
            ? [style.sideMenuButtonTouchStyle, sideMenuButtonTouchStyle]
            : [style.sideMenuButtonStyle, sideMenuButtonStyle]
        }
        onPress={() => {
          this.setState({
            id: item?.id,
            iconData: true,
          });
        }}
      >
        {item.id == tabIconId ? item?.pressImage : item?.image}
        <View>
          <Text
            style={
              item.id == id && iconData
                ? [style.sideMenuTouchNameStyle, sideMenuTouchNameStyle]
                : [style.sideMenuNameStyle, sideMenuNameStyle]
            }
          >
            {item?.name}
          </Text>
          <Text
            style={
              item.id == id && iconData
                ? [
                    style.sideMenuDescriptionTouchStyle,
                    sideMenuDescriptionTouchStyle,
                  ]
                : [style.sideMenuDescriptionStyle, sideMenuDescriptionStyle]
            }
          >
            {item?.description}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderSideTabData = ({ item }: any) => {
    const {
      sideTabButtonTouchTextStyle,
      sideTabButtonTextStyle,
      barGradientColor,
      selectedTabGradientColor,
    } = this?.props;
    const { tabIconData, tabIconId } = this.state;
    return (
      <LinearGradientComponent
        colors={
          item.id == tabIconId && tabIconData
            ? selectedTabGradientColor?.length > 0
              ? selectedTabGradientColor
              : []
            : ["transparent", "transparent"]
        }
        style={{ borderRadius: 0 }}
      >
        <View style={style.tabView}>
          <TouchableOpacity
            style={style.sidebuttoniconNameStyle}
            onPress={() => {
              this.setState({
                tabIconData: true,
                tabIconId: item?.id,
              });
            }}
          >
            {item.id == tabIconId ? item?.pressImage : item?.image}
            <Text
              style={
                item.id == tabIconId && tabIconData
                  ? [
                      style.sideTabButtonTouchTextStyle,
                      sideTabButtonTouchTextStyle,
                    ]
                  : [style.sideTabButtonTextStyle, sideTabButtonTextStyle]
              }
            >
              {item?.name}
            </Text>
          </TouchableOpacity>
          {item.id == tabIconId && tabIconData && (
            <>
              {barGradientColor?.length > 0 && (
                <LinearGradientComponent colors={barGradientColor}>
                  <View style={style.backViewStyle} />
                </LinearGradientComponent>
              )}
            </>
          )}
        </View>
      </LinearGradientComponent>
    );
  };
  render() {
    const {
      mainTitle,
      subMenuData,
      sideButtonTabData,
      source,
      subMenu,
      tabMenu,
      smallTabBarContainer,
      sideBarContainer,
      sideBartitleStyle,
      imageIcon,
      sideBartitleView,
      children,
    } = this?.props;
    const { id, iconData, tabIconData, tabIconId } = this.state;
    return (
      <View style={style.mainView}>
        <View style={style.subView}>
          {tabMenu && (
            <View style={[style.smallTabBarContainer, smallTabBarContainer]}>
              <View>{imageIcon}</View>
              <View>
                <FlatList
                  testID="tabMenuItem"
                  data={sideButtonTabData?.slice(0, 5)}
                  keyExtractor={(item, index) => `item-${index}`}
                  renderItem={(item: any) => this?.renderSideTabData(item)}
                  extraData={[tabIconData, tabIconId]}
                />
              </View>
              <View style={style.avtarView}>
                <Avatar image={source} style={style.avtarStyle} />
              </View>
            </View>
          )}
          {subMenu && (
            <View style={[style.sideBarContainer, sideBarContainer]}>
              <View style={sideBartitleView}>
                <Text
                  style={[style.sideBartitleStyle, sideBartitleStyle]}
                  testID="mainTitle"
                >
                  {mainTitle}
                </Text>
              </View>
              <ScrollView
                nestedScrollEnabled={true}
                showsVerticalScrollIndicator={false}
              >
                <FlatList
                  data={subMenuData}
                  testID="subMenuItem"
                  keyExtractor={(item, index) => `item-${index}`}
                  renderItem={(item: any) => this?.renderSideMenuData(item)}
                  extraData={[iconData, id]}
                />
              </ScrollView>
            </View>
          )}
        </View>
        <View>{children}</View>
      </View>
    );
  }
}

export default SideMenu;
