import { StyleSheet } from 'react-native';
import COLORS from '../../utils/colors';

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingHorizontal: 10
  },
  text: {
    marginHorizontal: 10,
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 24,
  },
  flatlist: {
    borderRadius: 1000,
    backgroundColor: COLORS.COLOR_INFO,
    padding:4,
    // height:60,
    alignItems:'center',
    overflow: 'hidden',
  },
  calendarIcon: {
    width: 16,
    height: 16,
    marginLeft: 10,
    // tintColor:COLORS.COLOR_MEDIUMBLUE
  }
});
export default styles;