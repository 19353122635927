import React, { Component, ReactNode } from "react";

import { Size, Theme } from "../utils/constants";

export type ThemeBoxProps = {
  theme: `${Theme}`;
  size?: `${Size}`;
  children?: ReactNode | React.JSX.Element;
};

export class ThemeBox<XProps, XState> extends Component<
  XProps & ThemeBoxProps,
  XState
> {
  constructor(props: XProps & ThemeBoxProps) {
    super(props);
  }

  render() {
    return this.props.children;
  }
}

export function ThemeBoxFunctional<T>({
  children,
  ...props
}: ThemeBoxProps & T) {
  const Children = children as React.JSX.ElementType;

  return <Children {...props} />;
}
