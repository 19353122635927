//@ts-nocheck
import React from "react";
import style from "./style";
import {
  TouchableOpacity,
  View,
  Text,
} from "react-native";
import Input from "../../Input";
import Avatar from "../../Avatar";
import { NavBarProps } from "./interface"



class NavBar extends React.Component<NavBarProps> {
  renderButtonName = (name: string) => {
    return (
      <TouchableOpacity key={name}>
        <Text style={[style.navButtonNameStyle, this.props.buttonTextStyle]}>
          {name}
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    const {
      headerLogoImage,
      inputBox = true,
      inputBoxPlaceholder = "search",
      inputIcon,
      navRightIcon,
      cartItem = false,
      cartItemCount,
      navSecondRightIcon,
      userIcon,
      loginIcon = true,
      source,
      navButtonName,
      containerStyle,
      loginText,
      loginTextStyle,
    } = this.props;

    return (
      <View style={[style.contanier, containerStyle]}>
        <View style={style.flexStyle}>
          {headerLogoImage}
          <View style={style.flexStyle}>
            {navButtonName?.map(this.renderButtonName)}
          </View>
        </View>
        <View>
          {inputBox && (
            <Input
              placeholder={inputBoxPlaceholder}
              leftIcon={inputIcon}
              size={"medium"}
              style={style.inputStyle}
            />
          )}
        </View>
        <View style={style.flexStyle}>
          {loginIcon ? (
            <View style={style.flexStyle}>
              {userIcon}
              <Text style={[style.loginText, loginTextStyle]}>{loginText}</Text>
            </View>
          ) : (
            <Avatar image={source} style={style.avtarStyle} />
          )}
          {navSecondRightIcon && <View>{navSecondRightIcon}</View>}
          {navRightIcon && (
            <View>
              {navRightIcon}
              {cartItem ? (
                <View style={style.cartStyle}>
                  <Text style={style.cartText}>{cartItemCount}</Text>
                </View>
              ) : null}
            </View>
          )}
        </View>
      </View>
    );
  }
}

export default NavBar;
