//@ts-nocheck
import React, { Component } from "react";
import { TouchableOpacity, View, Text, Image } from "react-native";
import styles from "./styles";
import Button from "../Button/Button";
import STRINGS from "../../utils/strings";
import STRINGS_TESTCASE from "../../utils/stringsTestCase";

export interface PopoverProps {
  /** style for the popover container */
  containerStyle?: {};
  /** style for the icon container */
  /** title text of the popover */
  titleText?: string;
  /** style for the title text */
  titleStyle?: {};
  /** description text of the popover */
  descriptionText?: string;
  /** style for the description text */
  descriptionStyle?: {};
  /** style for the bottom view container */
  footerStyle?: {};
  /** style for the button container */
  btnStyle?: {};
  /** style for the text on the button */
  txtStyle?: {};
  /** url for the close icon */
  closeIcon?: React.ReactNode;
  /** text on the bottom of the popover */
  buttonText1?: string;
  /** text on the button of the popover */
  buttonText2?: string;
  /** color 1 for the gradient on the button */
  gradientColor1: string;
  /** color 2 for the gradient on the button */
  gradientColor2: string;
}

interface PopoverState {
  isModalVisible: boolean;
}

class Popover extends Component<PopoverProps, PopoverState> {
  constructor(props: PopoverProps) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  onPressCloseIcon = () => {};

  onPressButton = () => {};

  render() {
    const {
      containerStyle,
      titleText = STRINGS.DEFAULT_TXT_POPOVR_1,
      titleStyle,
      descriptionText = STRINGS.DEFAULT_TXT_POPOVR_2,
      descriptionStyle,
      footerStyle,
      btnStyle,
      txtStyle,
      closeIcon,
      buttonText1,
      buttonText2,
      gradientColor1,
      gradientColor2,
    } = this.props;

    return (
      <View style={[styles.innerContainer, containerStyle]}>
        <TouchableOpacity
          onPress={() => this.onPressCloseIcon()}
          testID={STRINGS_TESTCASE.POPOVER_INPUT_COMPONENT_ICON}
          style={styles.iconContainer}
        >
          {closeIcon ? (
            closeIcon
          ) : (
            <Image
              source={{ uri: "https://i.imgur.com/amHs6X5.png" }}
              style={[styles.crossIcon]}
            />
          )}
        </TouchableOpacity>
        <Text style={[styles.txtTitle, titleStyle]}>{titleText}</Text>
        {/* <View style={styles.horizontalLine} /> */}
        <Text style={[styles.txtDescription, descriptionStyle]}>
          {descriptionText}
        </Text>
        <Text style={[styles.txtFooter, footerStyle]}>{buttonText1}</Text>
        {/* <View style={[height:25]}/> */}
        <View style={styles.buttonStyle}>
          {buttonText2 && (
            <Button
              theme="primary"
              style={btnStyle}
              textStyle={[styles.txtDelAccount, txtStyle]}
              onPress={() => this.onPressButton()}
              gradientColors={[gradientColor1, gradientColor2]}
            >
              {buttonText2}
            </Button>
          )}
        </View>
      </View>
    );
  }
}

export default Popover;
