import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
    fontWeight: '500',
    color: COLORS.COLOR_WHITE,
    // fontFamily: 'Inter-Medium',
  },
  iconStyle: {
    height: 16,
    width: 16,
    marginBottom: 8,
    alignSelf: 'center',
    tintColor: 'white',
  },
  selectedTab: {
    borderWidth: 1,
    borderColor: COLORS.COLOR_WHITE,
    backgroundColor: COLORS.COLOR_WHITE,
    borderRadius: 50,
  },
  selectedIconStyle: {
    tintColor: COLORS.COLOR_MEDIUMBLUE,
  },
  selectedText: {
    color: COLORS.COLOR_MEDIUMBLUE,
  },
  unselectedText: {
    color: COLORS.COLOR_GRAY,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft:5,
    marginRight:5
    // paddingHorizontal: 30,
  },
  flatlist: {
    // borderRadius: 350,
    paddingVertical: 10,
    // paddingHorizontal:5
    // flex:1
    // width:"100%"
  },
  mainView: {
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  flatListView: {
    // position: 'absolute',
    bottom: 2,
  },
  flatListContainer: {
    borderRadius: 30,
    // position: 'absolute',
  },
})

export default styles
