import { StyleSheet } from "react-native";
import COLORS from "../../../utils/colors";

export default StyleSheet.create({
  checkBoxButtonStyle: {
    width: 20,
    height: 20,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: COLORS.COLOR_DISABLE_GREY,
  },
  checkBoxSelectedButtonStyle: {
    width: 20,
    height: 20,
    borderRadius: 5,
    backgroundColor: COLORS.COLOR_MEDIUMBLUE,
    justifyContent: "center",
  },
  checkBoxTextStyle: {
    fontSize: 16,
    color: COLORS.COLOR_LISTTEXT,
    marginLeft: 10,
  },
  checkBoxViewStyle: {
    flexDirection: "row",
    marginBottom: 15,
    alignItems:'center'
  },
  symbol: {
    width: 10,
    height: 10,
    alignSelf: "center",
  },
  checkBoxDisableButtonStyle: {
    width: 20,
    height: 20,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: COLORS.COLOR_GREY_INPUT,
  },
  checkBoxDisableSelectedButtonStyle: {
    width: 20,
    height: 20,
    borderRadius: 5,
    backgroundColor: COLORS.COLOR_GREY_INPUT,
    justifyContent: "center",
  },
  disableCheckBoxTextStyle: {
    fontSize: 16,
    color: COLORS.COLOR_GRAY_PLACEHOLDER,
    marginLeft: 5,
  },
  checkBoxGroupTitleStyle: {
    fontSize: 14,
    color: COLORS.COLOR_GRAY,
    marginBottom: 15,
  },
  linearcheckBoxSelectedButtonStyle: {
    width: 20,
    height: 20,
    borderRadius: 5,
    justifyContent: "center",
  },
});
