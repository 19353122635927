import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'

const styles = StyleSheet.create({
  text: {
    alignSelf: 'center',
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '700',
    marginHorizontal: 8,
  },
  icon: {
    width: 20,
    height: 20,
    // marginLeft: 5,
  },
  defaultChips: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 26,
    backgroundColor: COLORS.COLOR_LIGHTPURPLE,
  },
  notActiveChips: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 26,
    backgroundColor: COLORS.COLOR_BTNGRAY,
  },
  activeChips: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 26,
    // backgroundColor: 'red',
    display: 'flex',
  },
})
export default styles
