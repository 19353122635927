//@ts-nocheck

import React, { Component } from "react";
import { View, TextInput, Text } from "react-native";
import COLORS from "../../utils/colors";
import styles from "./styles";

export interface textAreaProps {
  placeholder?: string;
  error?: string;
  numberOfLines?: number;
  maxLength?: number;
  mainContainer?: object;
  /** Style for text container */
  style?: object;
  errorStyle?: object;
  onChangeText?: (text: string) => void;
  textStyle?: object;
  highlightColor?: string;
  label?: string;
  showLimit?: boolean;
  labelTextStyle?: object;
  characterLimitStyle?: object;
  labelContainerStyle?: object;
}

interface textAreaState {
  value: string;
}

export const getBorderColor = (
  error?: string,
  value?: string,
  color?: string
) => {
  if (error) {
    return COLORS.COLOR_ERROR;
  } else if (value) {
    return color ? color : COLORS.COLOR_HIGHLIGHTBLUE;
  } else {
    return COLORS.COLOR_GREY_INPUT;
  }
};

class TextArea extends Component<textAreaProps, textAreaState> {
  constructor(props: textAreaProps) {
    super(props);

    this.state = {
      value: "",
    };
  }

  onChangeValue = (value: string) => {
    this.setState({ value });
    if (this.props.onChangeText) {
      this.props.onChangeText(value);
    }
  };

  render() {
    const {
      placeholder,
      error,
      numberOfLines,
      maxLength = 50,
      mainContainer,
      style,
      errorStyle,
      textStyle,
      highlightColor,
      label,
      showLimit,
      labelTextStyle,
      characterLimitStyle,
      labelContainerStyle,
    } = this.props;

    const { value } = this.state;

    return (
      <View style={mainContainer}>
        {label && (
          <View style={[styles.labelContainer, labelContainerStyle]}>
            <Text style={[styles.label, labelTextStyle]}>{label}</Text>
          </View>
        )}
        <View
          style={[
            styles.container,
            { borderColor: getBorderColor(error, value, highlightColor) },
            style,
          ]}
        >
          <TextInput
            style={[styles.input, textStyle]}
            onChangeText={this.onChangeValue}
            value={value}
            placeholder={placeholder}
            placeholderTextColor={COLORS.COLOR_GRAY_PLACEHOLDER}
            editable
            multiline
            numberOfLines={numberOfLines}
            maxLength={maxLength}
          />
        </View>
        {showLimit && (
          <Text style={[styles.characterLimit, characterLimitStyle]}>
            {`${value.length}/${maxLength}`}
          </Text>
        )}
        {!!error && (
          <View>
            <Text style={[styles.error, errorStyle]}>{error}</Text>
          </View>
        )}
      </View>
    );
  }
}

export default TextArea;
