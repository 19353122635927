import { StyleSheet } from 'react-native'
import { moderateScale } from '../../utils/screenRatio'
import COLORS from '../../utils/colors'

const styles = StyleSheet.create({
  text: {
    alignSelf: 'center',
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '700',
    marginHorizontal: 10,
  },
  icon: {
    width: 20,
    height: 20,
  },
  textButton: {
    justifyContent: 'center', 
    alignItems: 'center',
  },
});
export default styles;
