//@ts-nocheck
import React, { Component } from "react";
import { Animated, Easing, View } from "react-native";
import {
  ProgressIndicatorCircularProps,
  ProgressIndicatorProps,
} from "../interface";
import style from "../style";

class ProgressIndicatorCircular extends Component<
  ProgressIndicatorCircularProps & ProgressIndicatorProps
> {
  spinValue: Animated.Value;
  constructor(props) {
    super(props);
    this.spinValue = new Animated.Value(0);
  }

  componentDidMount() {
    this.spin();
  }

  spin = () => {
    this.spinValue.setValue(0);
    Animated.timing(this.spinValue, {
      toValue: 1,
      duration: 1000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => this.spin());
  };

  render() {
    const {
      progressIndicatorDarkTheme,
      progressDarkThemeMainContainer,
      progressMainContainer,
      progressDarkSpinner,
      progressSpinner,
    } = this.props;

    const spinInterpolate = this.spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ["0deg", "360deg"],
    });

    const progressDarkThemeMainContainerStyle = progressIndicatorDarkTheme
      ? [style.progressDarkThemeMainContainer, progressDarkThemeMainContainer]
      : [style.progressMainContainer, progressMainContainer];

    const progressDarkSpinnerStyle = progressIndicatorDarkTheme
      ? [style.progressDarkSpinner, progressDarkSpinner]
      : [style.progressSpinner, progressSpinner];

    return (
      <View style={progressDarkThemeMainContainerStyle}>
        <Animated.View
          style={[
            progressDarkSpinnerStyle,
            { transform: [{ rotate: spinInterpolate }] },
          ]}
        ></Animated.View>
      </View>
    );
  }
}

export default React.memo(ProgressIndicatorCircular);
