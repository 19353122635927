//@ts-nocheck
import React, { Component } from "react";
import { View, Image } from "react-native";
import { styles } from "./styles";

export interface HeaderProps {
  image?: React.ReactNode;
}

class Map extends Component<HeaderProps> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {};
  }
  render() {
    const { image } = this.props;

    return (
      <View>
        {image || (
          <Image
            source={{ uri: "https://i.imgur.com/Y7Wu91H.png" }}
            style={[styles.imgStyle]}
          />
        )}
      </View>
    );
  }
}

export default Map;
