import { Dimensions, StyleSheet } from "react-native";
import {
  horizontalScale,
  moderateScale,
  verticalScale,
} from "../../utils/screenRatio";

export default StyleSheet.create({
  coloumnView: {
    flexDirection: "row",
  },
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: "#F8FAFC",
    // height:Dimensions.get('window').height,
    width: Dimensions.get("window").width,
  },
  header: {
    flexDirection: "row",
    paddingBottom: verticalScale(5),
    borderBottomColor: "#CBD5E1",
    borderBottomWidth: 1,
    height: 38,
    justifyContent: "center",
  },
  headerText: {
    flex: 1,
    color: "#475569",
    fontSize: 14,
    paddingHorizontal: horizontalScale(10),
  },
  titleStyle: {
    color: "#64748B",
    fontSize: 12,
    marginLeft: horizontalScale(10),
  },
  textStyle: {
    borderBottomWidth: 1,
    borderBottomColor: "#CBD5E1",
    height: 42,
    justifyContent: "center",
  },
  headerDataContainerStyle: {
    flexDirection: "row",
  },
});
