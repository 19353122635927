//@ts-nocheck
import React, { Component } from "react";
import { StyleProp, TextStyle } from "react-native";
import Radiobuttonmultiple from "./component/radiobuttonmultiple";
import RadioButtonSingle from "./component/radiobuttonsingle";
import {
  RadioButtonMultipleProps,
  RadioButtonProps,
  RadioButtonSingleProps,
} from "./interface";

class RadioButton extends Component<
  RadioButtonProps & (RadioButtonMultipleProps | RadioButtonSingleProps)
> {
  render() {
    return (
      <>
        {this?.props?.radioButtonGroup == false ? (
          <RadioButtonSingle {...this?.props} />
        ) : (
          <Radiobuttonmultiple {...this?.props} />
        )}
      </>
    );
  }
}
export default RadioButton;
