import moment from "moment";

import React, { Component } from "react";
import { TouchableOpacity, View, Text, Image, Platform } from "react-native";
import { Calendar } from "react-native-calendars";
import { ICalendar, ICalendarState } from "./interface";
import { formatedDate, periodConstructor } from "./utils";
import { styles } from "./styles";
import COLORS from "../../../utils/colors";
import OutsideClickHandler from "../../OutsideClickHandler/OutsideClickHandler";

class DateRangeCalendar extends Component<ICalendar, ICalendarState> {
  constructor(props: ICalendar) {
    super(props);
    this.state = {
      isCalendarVisible: false,
      fromDate: "",
      toDate: "",
    };
  }

  // ### On day press
  _onDayPress = (dateString: string) => {
    const { markingType } = this.props;
    const { fromDate, toDate } = this.state;

    if (markingType == "period") {
      if (!fromDate) {
        this.setState({ fromDate: dateString, toDate: "" });
      } else if (fromDate) {
        const isNewDateBigger = Date.parse(dateString) > Date.parse(fromDate);

        if (isNewDateBigger) {
          this.setState({ toDate: dateString });
        } else {
          this.setState({ toDate: toDate || fromDate, fromDate: dateString });
        }
      }
    } else {
      this.setState({ fromDate: dateString });
    }
  };

  render() {
    const {
      minDate,
      maxDate,
      markingType,
      markedDates,
      renderHeader,
      renderArrow,
      hidePlaceHolder,
      hidePlaceHolder2,
      placeHolder = "From Date",
      placeHolder2 = "To Date",
      displayDateFormat = "DD MMMM",
      calendarAsPopup = true,
      arrowLeft,
      arrowRight,
      placeholderContainer,
      calendarContainer,
      iconContainer,
      iconStyle,
      containerStyle,
      theme,
      ...restProps
    } = this.props;
    const momentNow = moment();
    const today = momentNow.format("YYYY-MM-DD");
    const todayCustomStyles = {
      container: styles.todayContainer,
      text: styles.todayText,
    };
    const todayMarker = { [today]: { customStyles: todayCustomStyles } };
    const selectedCustomStyles = {
      container: styles.selectedContainer,
      text: styles.selectedText,
    };
    const selectedMarker = {
      [this.state.fromDate]: {
        customStyles: selectedCustomStyles,
        selected: true,
      },
    };
    const period = periodConstructor(
      this.state.fromDate,
      this.state.toDate,
      todayMarker
    );
    const { isCalendarVisible, fromDate, toDate } = this.state;

    const arrowRightUrl = "https://i.imgur.com/zuh7pXB.png";
    const arrowLeftUrl = "https://i.imgur.com/5qQ3eEO.png";

    return (
      <>
        <View style={[{ flexDirection: "row" }, containerStyle]}>
          {!hidePlaceHolder ? (
            <TouchableOpacity
              onPress={() =>
                this.setState({ isCalendarVisible: !isCalendarVisible })
              }
              testID="calendarButton"
              style={[
                styles.placeholderContainer,
                { marginRight: 4 },
                placeholderContainer,
              ]}
            >
              <Text>
                {fromDate
                  ? formatedDate(fromDate, displayDateFormat)
                  : placeHolder}
              </Text>
            </TouchableOpacity>
          ) : null}
          {!hidePlaceHolder2 && markingType == "period" ? (
            <TouchableOpacity
              testID="button2"
              onPress={() =>
                this.setState({ isCalendarVisible: !isCalendarVisible })
              }
              style={[
                styles.placeholderContainer,
                { marginLeft: 4 },
                placeholderContainer,
              ]}
            >
              <Text>
                {toDate
                  ? formatedDate(toDate, displayDateFormat)
                  : placeHolder2}
              </Text>
            </TouchableOpacity>
          ) : null}
        </View>

        {isCalendarVisible ? (
          <OutsideClickHandler
            onPress={() => this.setState({ isCalendarVisible: false })}
          >
            <Calendar
              // ### Generic
              current={today}
              minDate={minDate}
              maxDate={maxDate}
              markingType={markingType || "custom"}
              markedDates={
                markedDates
                  ? markedDates
                  : markingType == "period"
                  ? period
                  : selectedMarker
              }
              enableSwipeMonths={true}
              // ### Render
              renderHeader={renderHeader}
              renderArrow={(direction) => {
                return (
                  <View style={[styles.iconContainer, iconContainer]}>
                    {direction == "right"
                      ? arrowRight || (
                          <Image
                            source={{ uri: arrowRightUrl }}
                            style={styles.iconStyle}
                          />
                        )
                      : arrowLeft || (
                          <Image
                            source={{ uri: arrowLeftUrl }}
                            style={styles.iconStyle}
                          />
                        )}
                  </View>
                );
              }}
              // ### OnPress
              onDayPress={(day) => {
                this._onDayPress(day.dateString);
              }}
              // ### Styles
              style={[
                styles.calendarContainer,
                calendarAsPopup && styles.calanderPopUp,
                calendarContainer,
                Platform.OS !== "web" ? { width: "100%" } : {},
              ]}
              theme={{
                calendarBackground: "transparent",
                textSectionTitleColor: COLORS.COLOR_GRAY,
                todayTextColor: COLORS.COLOR_LIGHTBLACK,
                dayTextColor: COLORS.COLOR_LIGHTBLACK,
                textDisabledColor: COLORS.COLOR_GRAY_PLACEHOLDER,
                textDayStyle: {
                  fontSize: 14,
                  fontWeight: "400",
                },
                ...theme,
              }}
              {...restProps}
            />
          </OutsideClickHandler>
        ) : null}
      </>
    );
  }
}

export default DateRangeCalendar;
