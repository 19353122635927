//@ts-nocheck
import React, { Component } from "react";
import { View, TouchableOpacity, Text } from "react-native";
import { IAttachment } from "./interface";
import { styles } from "./styles";
// import ContextMenu from '../ContextMenu'

class Attachment extends Component<IAttachment> {
  constructor(props: IAttachment) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      title,
      subTitle,
      titleStyle,
      subTitleStyle,
      onPress,
      progressPrecentage,
      progressStyle,
      index,
      containerStyle,
      centerContainer,
      leftContainer,
      rightContainer,
      leftIcon,
      rightIcon,
    } = this.props;
    return (
      <View
        style={[styles.container, styles.shodow, containerStyle]}
        key={index}
      >
        {progressPrecentage > 0 ? (
          <View
            style={[
              styles.progress,
              progressStyle,
              { width: `${progressPrecentage}%` },
            ]}
          />
        ) : null}
        <View style={[styles.leftContainer, leftContainer]}>
          {leftIcon || null}
        </View>
        <View style={[styles.centerContainer, centerContainer]}>
          <Text style={[styles.title, titleStyle]}>{title}</Text>
          <Text style={[styles.subTitle, subTitleStyle]}>{subTitle}</Text>
        </View>
        <TouchableOpacity
          style={[styles.rightContainer, rightContainer]}
          onPress={onPress}
        >
          {/* <ContextMenu
                    options={[
                        { id: 1, label: "Download", leftIcon: '', rightIcon: '', },
                    ]}
                    nestedOptions={[]}
                    IconName={letIconUrl}
                    iconStyle={{ width: 20, height: 20 }}
                    // popupPosition={{
                    //     top: 20, left: 0
                    // }}
                /> */}
          {rightIcon || null}
        </TouchableOpacity>
      </View>
    );
  }
}
export default Attachment;
