//@ts-nocheck
import React, { Component } from "react";
import { View, FlatList, Text, TouchableOpacity } from "react-native";
import styles from "./styles";
import STRINGS from "../../utils/strings";
import COLORS from "../../utils/colors";
import LinearGradientComponent from "../LinearGradient";
import { TabData } from "./GradientBottomTab";

export interface BottomTabProps {
  tabData: TabData;
  containerStyle?: object;
  flatListView?: object;
  flatListContainer?: object;
  activeIndex?: number;
  selectedTabStyle?: object;
  selectedTextStyle?: object;
  unSelectedTextStyle?: object;
  unselectedTabStyle?: object;
  onIndexChange?: (text: string) => void;
  gradientColor1?: string;
  gradientColor2?: string;
  isGradient?: boolean;
}

export interface itemprops {
  selectedItem?: any;
}

class BottomTab extends Component<BottomTabProps, itemprops> {
  constructor(props: BottomTabProps) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  componentDidMount() {
    const { activeIndex } = this.props;
    this.setState({ selectedItem: activeIndex });
  }

  componentDidUpdate(prevProps: BottomTabProps) {
    if (prevProps.activeIndex !== this.props.activeIndex) {
      this.setState({ selectedItem: this.props.activeIndex });
    }
  }

  onIndexChangeVal = (index) => {
    this.setState({ selectedItem: index });
    const { onIndexChange } = this.props;
    if (onIndexChange) {
      onIndexChange(index);
    }
  };

  renderItem = ({ item, index }) => {
    const { selectedItem } = this.state;
    const isSelected = selectedItem === index;

    return (
      <TouchableOpacity
        onPress={() => this.onIndexChangeVal(index)}
        testID={STRINGS.BOTTOM_TAB}
      >
        <View
          style={[
            styles.button,
            isSelected
              ? [styles.selectedTab, this.props.selectedTabStyle]
              : this.props.unselectedTabStyle,
          ]}
        >
          {item?.img && (isSelected ? item.imgSelected : item.img)}
          <Text
            style={[
              styles.text,
              isSelected
                ? [styles.selectedText, this.props.selectedTextStyle]
                : this.props.unSelectedTextStyle,
            ]}
          >
            {item?.name.slice(0, 12)}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    const {
      tabData,
      flatListView,
      flatListContainer,
      gradientColor1 = COLORS.COLOR_LIGHTBLUE,
      gradientColor2 = COLORS.COLOR_MEDIUMBLUE,
      isGradient = false,
    } = this.props;

    return (
      <View style={[styles.flatListView, flatListView]}>
        {!isGradient ? (
          <FlatList
            horizontal
            data={tabData}
            contentContainerStyle={[
              { backgroundColor: COLORS.COLOR_DARKBLUE },
              styles.flatlist,
              flatListContainer,
            ]}
            keyExtractor={(item: any) => item.id}
            renderItem={this.renderItem}
          />
        ) : (
          <LinearGradientComponent
            colors={[gradientColor1, gradientColor2]}
            style={{ height: 50, borderRadius: 40 }}
          >
            <FlatList
              horizontal
              data={tabData}
              keyExtractor={(item: any) => item.id}
              renderItem={this.renderItem}
              contentContainerStyle={[styles.flatlist, flatListContainer]}
            />
          </LinearGradientComponent>
        )}
      </View>
    );
  }
}

export default BottomTab;
